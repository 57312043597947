@font-face {
  font-family: "Futura BT";
  src: url("./fonts/futura-bt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenShinGothic-Normal";
  src: url("./fonts/GenShinGothic-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* 設定全局字體 */
body {
  font-family: "Futura BT", "GenShinGothic-Normal", sans-serif; /* 英文使用 Futura BT，中文使用 GenShinGothic-Normal*/
  background-color: #f9f9f9;
  color: #333;
}

header {
  display: flex;
  justify-content: flex-end !important;
  background-color: transparent !important;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.parent-container {
  position: relative;
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 讓容器佔滿視窗高度 */
  z-index: 10;
}

.background-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

h1 {
  font-size: 3rem;
  position: relative;
  margin-bottom: 64px;
  background-color: transparent;
  z-index: 30;
}

p {
  margin-top: 20px; /* 使用 margin-top 來增加空隙 */
}

.slider {
  height: 50vh; /* 將 slider 高度減半 */
}

.slider div {
  flex: 0 0 auto;
  scroll-snap-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
}

.slider img {
  object-fit: cover;
  width: 100%;
  max-height: 800px;
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.slider div:hover img {
  transform: scale(1.05);
}
.grid-item {
  flex: 1 1 auto; /* 讓每個 item 自動調整寬度 */
  min-width: 800px; /* 設置最小寬度，避免過小 */
  max-width: 2000px; /* 設置最大寬度 */
  min-height: 400px; /* 根據需要設置最小高度 */
  width: auto;
  height: auto; /* 高度根據內容自動調整 */
  margin: 10px;
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 30px; /* gutter size */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Hover effect for grid items */
.grid-item:hover {
  transform: translateY(-10px);
}

/* 根據內容自適應寬度 */
.grid-item.auto-width {
  width: auto;
  min-width: 800px; /* 設置最小寬度，避免過小 */
  max-width: 1200px; /* 設置最大寬度，控制元素擴展過大 */
}

.container {
  max-width: 100%;
  width: 100%; /* 確保容器的寬度不超過螢幕寬度 */
  padding: 20px;
}

section {
  width: 100%; /* 確保 section 的寬度也是 100% */
  margin: 0 auto; /* 保持水平居中 */
}

/* 當進入手機版本（小於 768px）時，減小 margin 和 padding */
@media (max-width: 768px) {
  section {
    margin: 20px 0; /* 減少上下間距 */
    padding: 10px; /* 減少內邊距 */
  }
}

/* 為這兩個 section 設置默認高度 200px */
.short-section {
  height: 200px;
}

/* 當進入手機版本（小於 768px）時，將高度減半 */
@media (max-width: 768px) {
  .short-section {
    height: 100px;
  }
}

.background-effect {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 調整 section 的內容 */
.contact-section {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  height: 100vh;
}

.contact-section .text-left {
  max-width: 60%;
}

@media (max-width: 768px) {
  .contact-secstart {
    flex-direction: column;
    text-align: center;
  }

  .contact-section .text-left {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

/* 像左邊靠齊的方式
/* nav { */
/*   background-color: #fffafa; */
/*   display: flex; */
/*   justify-content: flex-start; */
/*   align-items: center; */
/*   border-radius: 15px; */
/*   padding: 0px; */
/*   width: auto; /* 自動適應內容寬度 */
/*   margin: 10px auto; /* 將 nav 居中 */
/* } */

nav {
  background-color: #fffafa;
  display: flex;
  justify-content: space-between; /* 讓元素均勻分布 */
  align-items: center;
  border-radius: 15px;
  padding: 5px;
  /* width: auto; /* 自動適應內容寬度 */
  margin: 10px auto; /* 將 nav 居中 */
  flex-grow: 1; /* 讓每個項目填滿空間 */
  width: min(80vw, 2500px);
  margin: 10px auto;
  padding: 5px clamp(10px, 2vw, 40px);
  max-width: 2500px;
  width: 80vw; /* 使用視窗寬度的 80% */
}
nav a {
  margin-left: 1rem;
  text-decoration: none;
  font-size: 1.125rem;
  flex-grow: 1; /* 讓每個鏈接在導航欄中平均分配空間 */
  text-align: center; /* 對齊文本 */
}

nav a:hover {
  color: #06d6a0; /* 改變懸停時的顏色 */
  transform: scale(1.05); /* 懸停時輕微放大 */
}

/* 響應式設計：針對小屏幕 */
@media (max-width: 768px) {
  .text-7xl {
    font-size: 3rem; /* 調整標題字體大小 */
  }

  nav {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto; /* 水平滾動 */
    white-space: nowrap; /* 防止換行 */
    scrollbar-width: none; /* 隱藏滾動條 */
  }

  nav::-webkit-scrollbar {
    display: none; /* 隱藏 WebKit 瀏覽器的滾動條 */
  }

  nav a {
    padding: 0.5rem 1rem; /* 增加觸摸區域的大小 */
    font-size: 1rem; /* 調整字體大小 */
  }
}

/* 導航容器樣式 */
.nav-links {
  display: flex;
  flex-wrap: nowrap; /* 確保不換行，所有鏈接在同一行 */
  overflow-x: auto; /* 允許橫向滾動 */
  white-space: nowrap; /* 確保文字不換行 */
  padding: 10px 0;
  scrollbar-width: none; /* 隱藏默認滾動條 */
  justify-content: flex-start; /* 导航链接靠左对齐 */
}

.nav-links::-webkit-scrollbar {
  display: none; /* 隱藏 WebKit 瀏覽器的滾動條 */
}

/* 將首頁固定在左側 */
/* 首页链接保持在左侧 */
.nav-links a:first-child {
  margin-left: 0; /* 移除左侧的默认间距 */
  margin-right: auto; /* 自动分配右侧空间，确保其他链接居中 */
  padding-left: 0; /* 调整左边距，使首页更靠左 */
}

/* 其他链接样式 */
.nav-links a {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #06d6a0;
  border-radius: 25px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* 懸停效果 */
.nav-links a:hover {
  background-color: #06d6a0; /* 懸停時背景顏色變化 */
  transform: scale(1.05); /* 懸停時略微放大效果 */
}

/* 響應式設計 */
@media (max-width: 768px) {
  .nav {
    padding: 10px; /* 移動設備上的額外間距 */
    justify-content: flex-start; /* 導航欄靠左對齊 */
  }

  .nav a {
    padding: 10px 15px;
    font-size: 1rem; /* 調整手機上的文字大小 */
  }

  .nav-links a:first-child {
    margin-left: 0; /* 移除左侧的默认间距 */
    margin-right: auto; /* 自动分配右侧空间，确保其他链接居中 */
    padding-left: 0; /* 调整左边距，使首页更靠左 */
  }
}

h1,
h2 {
  font-family: "Futura BT", sans-serif; /* 英文使用 Futura BT */
  font-weight: 600;
  color: #4d4d4d;
}

.bg-gray-100 {
  background-color: #f5f5f5; /* 更淺的灰色背景 */
}

/* 確保錨點跳轉不會被固定的 header 遮擋 */
.gradient-bg {
  background: linear-gradient(
    145deg,
    #f9f9f9,
    #e0f7e9
  ); /* 白色到淺綠的漸層背景 */
}

.gradient-bg-dark {
  background: linear-gradient(145deg, #e0f7e9, #d6e9d7); /* 較暗的灰綠漸層 */
  color: #333;
}

.btn-primary {
  background-color: #d6e9d7;
  color: #333;
}

.btn-primary:hover {
  background-color: #c4d9c7;
}

.custom-cursor {
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  pointer-events: none;
  transition: transform 0.2s ease;
  mix-blend-mode: difference;
}

/* 禁用游標效果在手機上 */
@media (max-width: 768px) {
  .custom-cursor {
    display: none; /* 隱藏游標效果 */
  }
}

img {
  max-width: 100%;
  height: auto;
}

.slider-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .home-section {
    padding: 5rem;
  }
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.bg-parallax {
  background-image: url("../public/images/hero1.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

a {
  color: #334;
  text-decoration: underline;
}

a:hover {
  color: #06d6a0; /* 在 hover 時改變顏色 */
  text-decoration: underline; /* 確保 hover 時底線仍然存在 */
}

main {
  padding-top: 80px; /* 確保主內容不會被固定的頭部遮擋 */
}

.social-icons-contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem; /* Increase gap between icons */
  position: relative;
  margin-right: -160px;
}

.social-icons-contact a:first-child {
  margin-left: 0; /* 移除第一個元素的左邊距 */
  padding-left: 0; /* 移除第一個元素的左內邊距 */
}
/* 在大螢幕時調整樣式 */
@media (min-width: 1024px) {
  /* lg breakpoint */
  .social-icons-contact {
    margin-top: 0;
  }
}

.social-icons-contact a {
  margin-left: 1rem; /* 每個圖標之間增加 8rem 的間距 */
}

.social-icons-contact a:first-child {
  margin-left: 1.5rem; /* 第一個圖標不需要左邊的間距 */
}

.contact-section .social-icons-contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    text-align: center;
  }

  .contact-section .text-left {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

div {
  position: relative;
}

a-scene {
  z-index: -1; /* 確保背景效果在最底層 */
}

body {
  color: #000 !important; /* 全局應用黑色字體，優先級提升 */
  background-color: #f9f9f9;
}

h1,
h2,
h3,
p,
a {
  color: #000 !important; /* 強制應用黑色字體，確保優先級 */
}

a:hover {
  color: #06d6a0 !important; /* 修改 hover 時的顏色 */
}

.bento-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 手機版為三列 */
  grid-auto-rows: 200px; /* 固定每個格子的高度 */
  gap: 1rem;
  perspective: 1000px; /* 透視效果 */
}

.bento-item img {
  object-fit: cover; /* 確保圖片在格子中不變形 */
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.6s ease, box-shadow 0.3s ease; /* 平滑過渡 */
  transform-style: preserve-3d; /* 保留 3D 效果 */
}

.preview-section img {
  object-fit: contain; /* 確保預覽區域內圖片完整顯示 */
  width: 100%;
  max-height: 500px;
  border-radius: 1rem;
}

.bento-item:hover {
  transform: translateY(-0.5rem);
  transform: rotateY(15deg) rotateX(10deg); /* 3D 旋轉效果 */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* 增加陰影效果 */
}

@media (max-width: 768px) {
  .bento-grid {
    grid-template-columns: repeat(2, 1fr); /* 手機版調整為兩列 */
    grid-auto-rows: 150px; /* 手機版調整每格高度 */
  }
}

@media (min-width: 1024px) {
  .bento-grid {
    grid-template-columns: repeat(4, 1fr); /* 桌面顯示四列 */
    grid-auto-rows: 300px; /* 桌面調整每格高度 */
  }
}
.logo-container {
  display: flex;
  justify-content: flex-end; /* 將圖片推向右邊 */
}

.logo {
  height: auto;
  max-width: 150px; /* 默認圖片寬度為 150px */
  margin-right: 10px; /* 默認距離瀏覽器右邊 10px */
}

@media (min-width: 640px) {
  .logo {
    max-width: 100px; /* 螢幕寬度 640px 或以上，寬度為 100px */
    margin-right: 20px; /* 增加與瀏覽器右邊的距離 */
  }
}

@media (min-width: 768px) {
  .logo {
    max-width: 110px; /* 螢幕寬度 768px 或以上，寬度為 110px */
    margin-right: 40px; /* 增加與瀏覽器右邊的距離 */
  }
}

@media (min-width: 1024px) {
  .logo {
    max-width: 200px; /* 螢幕寬度 1024px 或以上，寬度為 200px */
    margin-right: 60px; /* 更大的螢幕，與右邊距離更遠 */
  }
}
/* 隱藏默認滾動條，適用於 Webkit 瀏覽器 (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* 自定義滾動條的寬度，讓它變窄 */
  background-color: transparent; /* 滾動條的背景透明 */
}

/* 自定義滾動條軌道 */
::-webkit-scrollbar-track {
  background-color: #fffafa; /* 淺色背景，與 nav 欄相似 */
  border-radius: 10px; /* 圓角效果 */
}

/* 自定義滾動條滑塊 */
::-webkit-scrollbar-thumb {
  background-color: rgba(
    255,
    245,
    245,
    0.8
  ); /* 非常淺的淡紅色，與 nav 欄相匹配 */
  border-radius: 10px; /* 讓滾動條看起來更現代和圓滑 */
  border: 2px solid transparent; /* 增加內部空隙使其看起來更細緻 */
  background-clip: padding-box; /* 防止內部邊框和背景重疊 */
}

/* 當滾動條被 hover 時，改變顏色 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 230, 230, 0.8); /* 當 hover 時顏色稍微加深 */
}

/* Firefox 滾動條樣式 */
* {
  scrollbar-width: thin; /* 將滾動條變細 */
  scrollbar-color: rgba(255, 245, 245, 0.8) #fffafa; /* 定義滾動條顏色和背景，與 nav 欄一致 */
}
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
}
.scroller {
  position: relative;
  z-index: 5; /* 确保它不会被覆盖 */
  overflow: hidden; /* 防止滚动超出容器 */
}

.scroller ul {
  display: flex;
  min-width: 100%; /* 确保内容不超出容器 */
  width: 100%; /* 让内容适应容器 */
  overflow: hidden;
}

.scroller ul li {
  flex: 0 0 auto;
  width: 33.33%; /* 设置每个 li 的宽度为容器的三分之一 */
  box-sizing: border-box;
}
/* 確保 infinite moving cards 區塊占整個螢幕寬度，並在移動設備上有良好的顯示 */
.content-container {
  display: flex;
  justify-content: center; /* 讓內容居中 */
  padding: 20px;
  width: 100%;
}

.right-section {
  width: 100%; /* 讓卡片區域佔滿寬度 */
  height: auto; /* 根據內容自動調整高度 */
  overflow-x: auto; /* 確保卡片可以在手機端水平滑動 */
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column; /* 在手機版時垂直排列 */
  }

  .right-section {
    width: 100%; /* 在手機上佔滿寬度 */
    margin-top: 20px; /* 與上方內容保持間距 */
  }

  nav {
    overflow-x: scroll; /* 確保手機可以水平滾動 */
  }
}
.hero-section {
  display: flex;
  flex-direction: column;
}
body,
html {
  overflow-x: hidden;
}

/* 橫向手機排版 (landscape mode)，針對小於 768px 寬度的橫向螢幕 */
@media (max-width: 768px) and (orientation: landscape) {
  /* 調整 content-container 的排版，確保 Infinite Moving Card 移動到 Slider 下方 */
  .content-container {
    flex-direction: column; /* 改為垂直排列 */
    align-items: center; /* 中央對齊 */
    margin-top: 20px; /* 增加上方間距 */
  }

  /* 調整 right-section，讓卡片區塊位於 Slider 下方，並保持寬度 */
  .right-section {
    width: 100%; /* 保持寬度佔滿 */
    margin-top: 20px; /* 增加與 Slider 的間距 */
  }

  /* 確保 Slider 不會與 Infinite Moving Card 重疊 */
  .slider {
    margin-bottom: 20px; /* Slider 下方留出足夠的空間 */
  }
}

/* 一般手機垂直佈局 */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column; /* 垂直排列 */
    align-items: center;
  }

  .right-section {
    width: 100%; /* 在垂直佈局時佔滿寬度 */
    margin-top: 20px; /* 與上方內容保持間距 */
  }
}
