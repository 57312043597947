/* Grid layout for cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Card Styling */
.card {
  background-color: white;
  border-radius: 15px; /* 導圓角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 陰影效果 */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.02); /* 懸停時稍微放大 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* 懸停時加強陰影效果 */
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.card p {
  font-size: 1rem;
  color: #666;
}

/* Optional: 可以加入背景漸層 */
.card {
  background: linear-gradient(145deg, #f0f0f0, #e0e0e0);
}
