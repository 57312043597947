@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

.snap-container {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
}

.snap-section {
  scroll-snap-align: start;
  height: 100vh; /* 滿屏高度 */
  display: flex; /* 使用 flex 來做垂直和水平居中 */
  justify-content: center;
  align-items: center;
  text-align: left;
}
.slider-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media (max-width: 768px) {
  .snap-section {
    scroll-snap-type: none !important; /* 移除 scroll-snap 行為 */
  }

  .snap-section {
    scroll-snap-align: unset !important; /* 移除 scroll-snap 對齊 */
  }

  .slider div {
    scroll-snap-type: none !important; /* 移除 slider 的 scroll snap */
  }

  .slider div {
    scroll-snap-align: unset !important; /* 禁用 slider 的 scroll snap 對齊 */
  }
}
.custom-cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(6, 214, 160, 0.8);
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

h1,
h2 {
  font-family: "Futura BT", sans-serif;
  font-weight: 600;
}

.bg-green {
  background-color: transparent;
}
