/* src/components/Loading.css */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Background color during loading */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 1s ease-out; /* Fades out when loaded */
}

.loading-container.fade-out {
  opacity: 0;
  pointer-events: none; /* Prevent interaction during the fade-out */
}

.loading-logo {
  width: 150px;
  height: auto;
  opacity: 0;
  animation: fadeIn 2s forwards; /* Slowly fades in the logo */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
